import React from "react"

import Content from "../components/Content"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Our Contact Details" />
    <Content />
  </Layout>
)

export default IndexPage
