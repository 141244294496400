import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import bgimg from "../images/bgimg.jpg"

const Content = () => {
  const data = useStaticQuery(graphql`
    query {
      ctlogo: file(relativePath: { eq: "CT-logo-light.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      truck: file(relativePath: { eq: "truck.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Fullpage>
      <div style={{ width: 200, margin: 20 }}>
        <Img
          fluid={data.ctlogo.childImageSharp.fluid}
          alt="Crosby Transport Logo"
          style={{ width: "100%", padding: 0 }}
        />
      </div>

      <Card>
        <Title>Brett Crosby</Title>

        <Button as="a" href="tel:021444147">
          021 444 147
        </Button>
        <EmailButton as="a" href="mailto:office@crosbytransport.co.nz">
          office@crosbytransport.co.nz
        </EmailButton>
        <Location>Auckland, New Zealand</Location>
      </Card>
    </Fullpage>
  )
}

export default Content

const Fullpage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle at bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(74, 74, 74, 1) 92%
  );
  background-image: url(${bgimg});
  background-repeat: no-repeat;
  background-size: cover;
`
const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 300px;
  width: 350px;
  height: 200px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.59);
`
const Title = styled.h1`
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  color: #2a2f61;
  margin: 0;
  padding: 0;
`

const Button = styled.button`
  display: inline-block;
  color: #2a2f61;
  font-size: 1em;
  text-decoration: none;
  display: block;
`

const EmailButton = styled(Button)`
  font-size: 1.1em;
`
const Location = styled.p`
  font-size: 12px;
  color: #2a2f61;
`
